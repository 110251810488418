import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingSpinnerService } from '../utils/loading-spinner/service/loading-spinner.service';
import { finalize } from 'rxjs';

let ongoingRequests = 0;

export const SKIP_SPINNER = new HttpContextToken<boolean>(() => false);

export const loadingSpinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoadingSpinnerService);

  const skipSpinner = req.context.get(SKIP_SPINNER);

  if (!skipSpinner) {
    if (ongoingRequests === 0) {
      loaderService.setLoading(true);
    }
    ongoingRequests++;
  }

  return next(req).pipe(
    finalize(() => {
      if (!skipSpinner) {
        ongoingRequests--;
        if (ongoingRequests === 0) {
          loaderService.setLoading(false);
        }
      }
    })
  );
};