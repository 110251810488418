import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable, signal } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment as env } from '../../environments/environment';
import { catchError, of } from 'rxjs';
import { SKIP_SPINNER } from '../interceptor/loading-spinner.interceptor';
import { BYPASS_AUTH } from '../interceptor/authentication.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  platform = signal<string>('');

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {}

  setPlatform(platform: string) {
    this.platform.set(platform);
  }

  reportError(error: any) {
    const userAgent = this.document.defaultView?.navigator.userAgent || 'unknown';
    const location = this.document.location || {};

    const errorReport = {
      message: error.message,
      stack: error.stack,
      userAgent: userAgent,
      platform: Capacitor.getPlatform(),
      urlDetails: {
        href: location.href || 'unknown',
        origin: location.origin || 'unknown',
        protocol: location.protocol || 'unknown',
        hostname: location.hostname || 'unknown',
        port: location.port || 'unknown',
        pathname: location.pathname || 'unknown',
        host: location.host || 'unknown',
      }
    };
    console.log("reporting error imedia: ", JSON.stringify(errorReport));
    const context = new HttpContext().set(SKIP_SPINNER, true).set(BYPASS_AUTH, true);

    this.http.post(`${env.apiUrl}/error`, errorReport, {context})
      .pipe(
        catchError(err => {
          console.error('Error reporting failed', err);
          return of(null);
        })
      )
      .subscribe();
  }
}